function conventionsController(DatatableLang, $state, $scope, WS, $window, AppSettings) {
    'ngInject';
    const vm = this;
    vm.conventions = [];
    vm.table_type = 'Produits';
    vm.date_debut = "";
    vm.date_fin = "";
    vm.countCategorie = [];
    vm.show_loading = false;
    vm.searchbox = {};
    vm.tabs = {};
    vm.findActiveConventions = () => {
        WS.getData('conventions/findActiveConventions', { type: vm.table_type })
            .then(function (response) {
                if (response.status == 200) {
                    vm.conventions = response.data;
                    vm.countByCategorie();
                    $scope.$apply();


                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.getAllConventions = () => {
        WS.getData('conventions/getAllConventions')
            .then(function (response) {
                if (response.status == 200) {
                    vm.conventions = response.data;
                    $scope.$apply();


                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    vm.storeNewConvention = () => {
        vm.new.prods = vm.tabs;
        console.log(vm.new);
        var error = 0;
        var nbr_prods = 0;
        if(!vm.new.type){
            swal('Oups!!','Type convention obligatoire!','warning')
        }else if(!vm.new.type_client){
            swal('Oups!!','Type client obligatoire!','warning')
        }else if(!vm.new.date_debut){
            swal('Oups!!','Date début obligatoire!','warning')
        }else if(!vm.new.date_fin){
            swal('Oups!!','Date fin obligatoire!','warning')
        }else{
            _.each(vm.tabs, (element, index) => {
                if(element.selected){
                    nbr_prods++;
                    // cas de stock
                    if(vm.new.type== 'stock'){
                        if(!element.commentaire){
                            error++;
                            swal('Oups!!','Veuillez bien remplir le commentaire de produit '+ index,'warning');
                        }
                    }

                    if(vm.new.type== 'prix'){
                        if(!element.prix_min || !element.prix_max){
                            error++;
                            swal('Oups!!','Veuillez bien remplir les prix de produit '+ index,'warning');
                        }
                    }

                }
            });
            if(nbr_prods==0){
                swal('Oups!!','Veuillez bien choisir au moins un produit','warning');
            }
            if(error==0 && nbr_prods > 0){
                WS.post('conventions/storeNewConvention', vm.new)
                    .then(function (response) {
                        if (response.data.success == true) {

                            swal(
                                'Succès',
                                'Conventions ajoutée avec succée',
                                'success'
                            ).then(function () {
                                $window.location.reload();
                            }).catch(function () {
                                $window.location.reload();
                            });
                            $scope.$apply();

                        }
                    })
                    .then(null, function (error) {
                        console.log(error);
                    });
            }
        }

        
    }

    vm.select_type_clients = () => {
        WS.getData('type_clients').then(
            response => {
                vm.type_clients = response.data;
                $scope.$apply();
            },
            error => {

            }
        )
    }

    vm.select_categorie_clients = () => {
        WS.getData('categoriescommerciale').then(
            response => {
                vm.categorie_clients = response.data;
                $scope.$apply();
            },
            error => {

            }
        )
    }

    vm.changeDefaultType = () => {
        vm.conventions = [];
        vm.gamme_code = '';
        vm.famille_code = '';
        vm.sousfamille_code = '';
        vm.marque_code = '';
        switch (vm.table_type) {
            case "Produits":
                vm.defaultselected = vm.selectprods;
                break;
            case "Gammes":
                vm.defaultselected = vm.gammes;
                break;
            case "Familles":
                vm.defaultselected = vm.familles;
                break;
            case "SousFamilles":
                vm.defaultselected = vm.sousfamilles;
                break;
            case "Marques":
                vm.defaultselected = vm.marques;
        }

        _.each(vm.defaultselected, (element, index) => {
            if (!element.hasOwnProperty('gamme_code')) { vm.defaultselected[index].gamme_code = ''; }
            if (!element.hasOwnProperty('famille_code')) { vm.defaultselected[index].famille_code = ''; }
            if (!element.hasOwnProperty('sousfamille_code')) { vm.defaultselected[index].sousfamille_code = ''; }
            if (!element.hasOwnProperty('marque_code')) { vm.defaultselected[index].marque_code = ''; }
        });
        vm.findActiveConventions();

    }

    vm.selectProds = function () {
        WS.get('conventions/selectProds')
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectprods = response.data;
                    vm.defaultselected = vm.selectprods;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectCategories = function () {
        WS.get('conventions/selectCategories')
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectcategories = response.data;
                    vm.countByCategorie();
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getGammes = function () {
        WS.get('gammes')
            .then(function (response) {
                vm.gammes = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getFamilles = function () {
        WS.get('familles')
            .then(function (response) {
                vm.familles = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getSousfamilles = function () {
        WS.get('sousfamilles')
            .then(function (response) {
                vm.sousfamilles = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getMarque = function () {
        WS.get('marques')
            .then(function (response) {
                vm.marques = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.manageConventions = (categorie_code, value_selected) => {
        //the value_selected is produit_code or famille_code or gamme_code or marque_code or sousfamille_code
        //console.log(categorie_code, value_selected)
        var i = 0;
        _.each(vm.conventions, (element, index) => {
            if (element.value_selected == value_selected && element.categorie_code == categorie_code) {
                i++;
            }
        });

        if (i == 0) {
            vm.conventions.push({ value_selected, categorie_code, new: true });
        } else {
            vm.conventions = _.without(vm.conventions, _.findWhere(vm.conventions, { value_selected, categorie_code, new: true }));
        }
        vm.countByCategorie();
        //console.log(vm.conventions)

    }

    vm.checkIfSelected = (categorie_code, produit_code) => {
        var i = 0;
        var obj = {
            isExist: false,
            value: null
        }
        _.each(vm.conventions, (element, index) => {
            if (element.value_selected == produit_code && element.categorie_code == categorie_code) {
                i++;
                obj.value = element;
                obj.isExist = true;
            }
        });

        // if (i == 0) {
        //     return false;
        // } else {
        //     return true;
        // }
        return obj;
    };


    vm.storeConvention = function () {
        vm.show_loading = true;
        var conventions = _.reject(vm.conventions, d => d.new == false);
        var data = {
            date_debut: vm.date_debut,
            date_fin: vm.date_fin,
            type: vm.table_type,
            conventions: conventions
        };

        if (data.date_debut == "" || data.date_fin == "") {
            swal('Oups', 'Veuillez bien choisir date debut et date fin', 'warning');
        } else if (data.conventions.length == 0) {
            swal('Oups', 'Veuillez bien selectionner au moins une categorie', 'warning');
        } else {
            WS.post('conventions/storeConvention', data)
                .then(function (response) {
                    if (response.data.success == true) {
                        swal(
                            'Succès',
                            'Conventions ajoutée avec succée',
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        }).catch(function () {
                            $window.location.reload();
                        });
                    }
                    vm.show_loading = false;
                })
                .then(null, function (error) {
                    console.log(error);
                });
        }
    };

    vm.categorieSelected = (categorie_code, action) => {
        /**
         * if( action == true) insert else delete by categorie_code
         */
        //console.log(categorie_code, action);

        if (action == true) {
            _.each(vm.defaultselected, (element, index) => {
                var i = 0;
                _.each(vm.conventions, (convention, indexConv) => {
                    if (convention.value_selected == element.code && convention.categorie_code == categorie_code) {
                        i++;
                    }
                });
                if (i == 0) {
                    vm.conventions.push({
                        value_selected: element.code, categorie_code, new: true
                    })
                }
            });
        } else {
            vm.conventions = _.reject(vm.conventions, d => d.categorie_code == categorie_code && d.new == true);

        }
        vm.countByCategorie();
        //console.log(action, vm.conventions);

    };
    vm.produitSelected = (produit_code, action) => {
        /**
         * if( action == true) insert else delete by produit_code
         */
        // console.log(produit_code, action);

        if (action == true) {
            _.each(vm.selectcategories, (categorie, indCategorie) => {
                var i = 0;

                _.each(vm.conventions, (convention, indexConv) => {

                    if (convention.value_selected == produit_code && convention.categorie_code == categorie.code) {
                        i++;
                    }
                });
                if (i == 0) {
                    vm.conventions.push({
                        value_selected: produit_code, categorie_code: categorie.code, new: true
                    })
                }
            });
        } else {
            vm.conventions = _.reject(vm.conventions, d => d.value_selected == produit_code && d.new == true);

        }

        vm.countByCategorie();
        //console.log(action, vm.conventions);

    };


    vm.countByCategorie = () => {
        _.each(vm.selectcategories, (categorie, indCategorie) => {
            vm.countCategorie[categorie.code] = 0;
            _.each(vm.conventions, (convention, indexConv) => {

                if (convention.categorie_code == categorie.code) {
                    vm.countCategorie[categorie.code]++;
                }
            });
        });
        //console.log(vm.countCategorie);
    }

    vm.removeConvention = (convention, key_prod, key_categorie) => {
        //console.log(convention);
        swal({
            title: 'Convention',
            text: "voulez vous vraiment supprimer cette convention!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.delete('conventions/removeConvention/' + convention.id).then(
                function (response) {
                    if (response.data.success) {
                        vm.show_carte[key_prod][key_categorie] = false;
                        vm.conventions = _.reject(vm.conventions,
                            d => d.value_selected == convention.value_selected &&
                                d.categorie_code == convention.categorie_code &&
                                d.new == false
                        );
                        swal(
                            'Succès',
                            'Convention supprimée avec Succès.',
                            'success'
                        );
                        $scope.$apply();
                        vm.countByCategorie();

                    }

                },
                function () {
                    swal(
                        'Attention!',
                        'vous ne pouvez pas supprimer cette convention!!',
                        'warning'
                    );
                }
            );
        });
    }


    vm.updateConvention = () => {
        WS.post('conventions/updateConvention', vm.current_convention)
            .then(function (response) {
                if (response.data.success == true) {
                    // vm.conventions = _.reject(vm.conventions,
                    //     d => d.value_selected == vm.current_convention.value_selected &&
                    //         d.categorie_code == vm.current_convention.categorie_code &&
                    //         d.new == false
                    // );
                    // vm.conventions.push(vm.current_convention);
                    // vm.show_carte[vm.current_convention.key_prod][vm.current_convention.key_categorie] = false;

                    swal(
                        'Succès',
                        'Conventions modifiée avec succée',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    }).catch(function () {
                        $window.location.reload();
                    });
                    $scope.$apply();

                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.findInActiveConventions = () => {
        WS.getData('conventions/findInActiveConventions', { type: vm.table_type })
            .then(function (response) {
                if (response.status == 200) {
                    vm.conventions = response.data;
                    $scope.$apply();

                    vm.countByCategorie();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };
    
}
export default {
    name: 'conventionsController',
    fn: conventionsController
};