function tourneesController($translate, $state, $scope, WS, $window, DatatableLang) {
    'ngInject';
    const vm = this;
 
    vm.filter=  {};

    vm.days = [
        'Lundi',
        'Mardi',
        'Mercredi',
        'Jeudi',
        'Vendredi',
        'Samedi',
        'Dimanche'
    ];
    
    vm.getRouteWithDepot = function () {
        WS.get('routing/getRouteWithDepot')
            .then(function (response) {
                vm.routeswithdepot = response.data;
                $scope.$apply();
                /*$('.table_routes').DataTable({
                        "language": DatatableLang
                    });*/
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectUsers = function () {
        WS.get('users/selectUsers', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.findTournees = function () {
         
        console.log(vm.filter)
        WS.post('tournees/findTournees', vm.filter).then(
            function (response) {
                if (response.data.success == true) {
                    $scope.$apply();
                }
            },
            function (error) {
                console.log(error);
            });
    }

}
export default {
    name: 'tourneesController',
    fn: tourneesController
};